import { Website } from 'ui-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

const AppProvider = (props) => {
  const { children } = props

  return (
    <QueryClientProvider client={queryClient}>
      <Website>{children}</Website>
    </QueryClientProvider>
  )
}

export { AppProvider }
