import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import {
  GetUserByIdQuery,
  GetUserByIdQueryVariables
} from '~user-profile/types/__generated/gql/graphql'
import { graphierQL } from 'utils'

const query = /* GraphQL */ `
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      firstName
      lastName
      createdAt
      avatar
      defaultWorkspaceId
      membershipCollection {
        total
        items {
          id
          createdAt
          role
          workspace {
            id
            logo
            name
            description
            createdAt
          }
        }
      }
      tagCollection {
        items {
          id
          type
          name
          count
        }
        total
      }
    }
  }
`

export const getUserByIdQueryKey = (id: string) => ['user', id]

export type GetUserByIdVariables = GetUserByIdQueryVariables
export type GetUserByIdResults = GetUserByIdQuery['getUserById']

export const getUserByIdQueryFn = (variables: GetUserByIdVariables) =>
  graphierQL<GetUserByIdQuery, GetUserByIdVariables>({
    query,
    variables
  }).then((res) => res.getUserById)

export const useGetUserById = (
  id?: string,
  enabled?: boolean,
  initialData?: GetUserByIdResults
): UseQueryResult<GetUserByIdResults> => {
  return useQuery({
    enabled: enabled ?? !!id,
    queryKey: getUserByIdQueryKey(id!),
    queryFn: () => getUserByIdQueryFn({ id: id! }),
    initialData
  })
}

export const useGetCurrentUser = (
  initialData?: GetUserByIdResults
): UseQueryResult<GetUserByIdResults> => {
  const {
    query: { userId }
  } = useRouter()

  return useGetUserById(String(userId), true, initialData)
}

export const useGetCurrentUserCache =
  (): UseQueryResult<GetUserByIdResults> => {
    const {
      query: { userId }
    } = useRouter()

    return useGetUserById(String(userId), false)
  }
