import { withSSRContext } from 'aws-amplify'
import { GetServerSideProps } from 'next/types'
import { useEffect } from 'react'
import Analytics from 'react-ga4'
import { Home as App } from 'website'
import { WORKSPACE_TAB } from '~ui-components/index'
import { getUserByIdQueryFn } from '~user-profile/queries/useGetUserById'
import { getCurrentAuthenticatedUser } from '~utils/auth'
import {
  getCurrentWorkspaceFromRequestCookies,
  getSerializedCurrentWorkspaceCookieForResponseHeader
} from '~utils/functions'
import { getWorkspaceLink } from '~utils/routings'

const Home = () => {
  useEffect(() => {
    // Save page view whenever a user view this page.
    Analytics.send({
      hitType: 'pageview',
      page: '/'
    })
  }, [])

  return <App />
}

export default Home

export const getServerSideProps: GetServerSideProps = async (context) => {
  const user = await getCurrentUser(context)
  if (!user) return { props: {} }

  const currentWorkspaceCookie = getCurrentWorkspaceFromRequestCookies(
    context.req.cookies
  )
  if (currentWorkspaceCookie) {
    const { id, name } = currentWorkspaceCookie

    return {
      redirect: {
        destination: [
          getWorkspaceLink({ workspaceId: id, workspaceName: name }),
          '?tab=',
          WORKSPACE_TAB.MAPS
        ].join(''),
        permanent: false
      }
    }
  }

  const workspaceObj = user.membershipCollection.items.find(
    (i) => i.workspace.id === user.defaultWorkspaceId
  )?.workspace
  if (!workspaceObj) return { props: {} }

  const { id, name } = workspaceObj
  context.res.setHeader(
    'Set-Cookie',
    getSerializedCurrentWorkspaceCookieForResponseHeader({ id, name })
  )

  return {
    redirect: {
      destination: [
        getWorkspaceLink({ workspaceId: id, workspaceName: name }),
        '?tab=',
        WORKSPACE_TAB.MAPS
      ].join(''),
      permanent: false
    }
  }
}

export const getCurrentUser = async (context) => {
  const ssrContext = withSSRContext(context)
  try {
    const user = await getCurrentAuthenticatedUser(ssrContext)
    const getUserByIdResults = await getUserByIdQueryFn({
      id: user.signInUserSession.idToken.payload['custom:id']
    })

    return getUserByIdResults
  } catch (err) {
    console.log('Error fetching current user:', err)
    return null
  }
}
